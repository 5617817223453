<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col class="col-sm-6 col-md-6 col-lg-4 col-12">
            <div>
              <v-card class="mx-auto relative v-sheet theme--light white">
                <v-card-text>
                  <div class="d-flex flex-column justify-center align-center">
                    <div
                      style="width:40px; height: 40px"
                      class="rounded-circle  d-flex align-center justify-center mb-4 v-sheet theme--light primary lighten-5"
                    >
                      <v-icon>
                        fas fa-calendar-alt
                      </v-icon>
                    </div>

                    <h3
                      style="color:#304156"
                      class="text-h3 font-weight-bold ma-0 "
                    >
                      {{ data.schedules }}
                    </h3>
                    <h6 class="caption mb-8 font-weight-regular">
                      Compromissos a fazer
                    </h6>
                    <v-btn
                      class="caption hover:white--text  font-weight-bold py-2 px-4  d-inline-flex align-center v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary--text primary lighten-5 hover:bg-primary"
                      to="/app/agenda"
                    >
                      Ver Agenda
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>

          <v-col class="col-sm-6 col-md-6 col-lg-4 col-12">
            <div>
              <v-card class="mx-auto relative v-sheet theme--light white">
                <v-card-text>
                  <div class="d-flex flex-column justify-center align-center">
                    <div
                      style="width:40px; height: 40px"
                      class="rounded-circle  d-flex align-center justify-center mb-4 v-sheet theme--light primary lighten-5"
                    >
                      <v-icon>
                        fas fa-folder-open
                      </v-icon>
                    </div>
                    <h3
                      style="color:#304156"
                      class="text-h3 font-weight-bold ma-0 "
                    >
                      {{ data.demands }}
                    </h3>
                    <h6 class="caption mb-8 font-weight-regular">
                      Demandas atrasadas
                    </h6>
                    <v-btn
                      class="caption hover:white--text  font-weight-bold py-2 px-4  d-inline-flex align-center v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary--text primary lighten-5 hover:bg-primary"
                      to="/app/demandas"
                    >
                      Ver demandas
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>

          <v-col class="col-sm-6 col-md-6 col-lg-4 col-12">
            <div>
              <v-card class="mx-auto relative v-sheet theme--light white">
                <v-card-text>
                  <div class="d-flex flex-column justify-center align-center">
                    <div
                      style="width:40px; height: 40px"
                      class="rounded-circle  d-flex align-center justify-center mb-4 v-sheet theme--light primary lighten-5"
                    >
                      <v-icon>
                        fas fa-plus
                      </v-icon>
                    </div>
                    <h3
                      style="color:#304156"
                      class="text-h3 font-weight-bold ma-0 "
                    >
                      {{ data.requesters }}
                    </h3>
                    <h6 class="caption mb-8 font-weight-regular">
                      Solicitações abertas
                    </h6>
                    <v-btn
                      class="caption hover:white--text  font-weight-bold py-2 px-4  d-inline-flex align-center v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary--text primary lighten-5 hover:bg-primary"
                      to="/app/demandas/solicitacoes"
                    >
                      Ver solicitações
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import instanceAxios from "../../plugins/axios";

export default {
  data() {
    return {
      data: [],
    };
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  mounted() {
    try {
      if (this.me.role.name == "educa_ws") {
        this.$router.push({ path: "/app/academy" });
      }

      this.getHomeInfo();
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    async getHomeInfo() {
      const { data } = await instanceAxios.get("home/info");

      this.data = data;
    },
  },
};
</script>
